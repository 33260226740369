import Title from "ant-design-vue/lib/typography/Title";
import { defineStore, acceptHMRUpdate } from "pinia";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
export const useMenusStore = defineStore({
    id: "menus",
    state: () => ({
        fbxModel:false,
        TileModel:false,
        hasRoutes: false,
        editableTabsValue: "Index",
        editableTabs: [{
            title: "",
            name: "",
        }],
    }),

    actions: {

        changeRouteStatus(hasRoutes: boolean) {
            this.hasRoutes = hasRoutes;
        },

        addTable(tab: { name: string, title: string }) {
            const index = this.editableTabs.findIndex((e: { name: string, title: string }) => e.name === tab.name);
            if (index === -1) {
                this.editableTabs.push(
                    {
                        title: tab.title,
                        name: tab.name,
                    });
            }
            this.editableTabsValue = tab.name;
        },


    },
});
